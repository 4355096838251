import React from 'react';
import KirdFooter from 'partials/footer';
import KirdGnb from 'partials/gnb';
import CheckLogin from "partials/login/CheckLogin";
import SetI18n from 'partials/i18n/SetI18n';
import CheckMemberCdr from "partials/capacityDev/CheckMemberCdr";

type Props = {
    children:
        | JSX.Element
        | JSX.Element[]
        | string
        | string[];
};

export default function HomeLayout({children}: Props) {
    return (
        <div className='kirdContainer'>
            {/* 직무 및 경력단계 확인 팝업 */}
            <CheckMemberCdr></CheckMemberCdr>

            {/* GNB 영역 START */}
            <KirdGnb/>
            {/* GNB 영역 END */}

            {/* 메인 영역 START */}
            <div id="content">{children}</div>
            {/* 메인 영역 END */}

            {/* 로그인 체크 영역 START */}
            <CheckLogin/>
            {/* 로그인 체크 영역 END */}

            {/* i18n setting START */}
            <SetI18n />
            {/* i18n setting END */}

            <KirdFooter/>
        </div>
    );
};
