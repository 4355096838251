import {useTranslation} from 'next-i18next';
import styles from './SlidesTitle.module.css';

interface Props {
    id?: string,
    title: string,
}

const SlidesTitle = (props: Props) => {
    const {t} = useTranslation();

    return (
        <div className={styles.contentsTitGroup} id={props.id}>
            <div className={styles.title}><strong>{props.title}</strong></div>
            <div className={styles.pagenation}>
                <button className={['btnPrev', styles.btnPrev].join(' ')}>{t('이전')}</button>
                <button className={['btnNext', styles.btnNext].join(' ')}>{t('다음')}</button>
            </div>
        </div>
    );
};

export default SlidesTitle;
