import React, { useEffect, useRef, useState } from 'react';
import KirdContainer from 'components/layouts/KirdContainer';
import 'swiper/swiper-bundle.min.css';
import { useRouter } from 'next/router';
import { gql, useLazyQuery } from '@apollo/client';
import style from './EbookSlides.module.css';

const GET_USER = gql`
    query GetUser($roleCodeExists: Int) {
        user: getUser(roleCodeExists: $roleCodeExists) {
            loginId
            hasRole
        }
    }
`;

interface Props {
    id?: string,
    title: string,
    from?: boolean;
}

export default function EbookSlides(props: Props) {
    const {id, title, from} = props;
    const [loginInfo, setLoginInfo] = useState(null);
    
    // 로그인 사용자 조회
    const [fnGetUser] = useLazyQuery(GET_USER, {
        variables: { roleCodeExists: 1 }, // 이 부분에서 변수를 바인딩합니다.
        fetchPolicy: "no-cache",
        onCompleted: (res) => {
            if (res.user || res.user.loginId) {
                setLoginInfo(res.user.loginId)
            } 
     
            let loginUrl = "/";
            if (from) {
                loginUrl = `https://ebook.alpha-campus.kr/contents/ebook_new.asp?user_info=${res.user.loginId}`;  
            } else {
                loginUrl = `https://ebook.alpha-campus.kr/ebook_linkage.asp?user_info=${res.user.loginId}`;  
            }
                               
            setUrl(loginUrl);  
        }
    });

    const {isReady, query, asPath, push} = useRouter();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [overlayVisible, setOverlayVisible] = useState(true);
    const [url, setUrl] = useState("");
    const [height, setHeight] = useState('700px');
    
    useEffect(() => {
        if (isReady) {
            fnGetUser();
        }
    }, [query]);

    useEffect(() => {
        const handleIframeLoad = () => {
            setOverlayVisible(true);
        };

        if (iframeRef.current) {
            iframeRef.current.addEventListener('load', handleIframeLoad);
        }

        return () => {
            if (iframeRef.current) {
                iframeRef.current.removeEventListener('load', handleIframeLoad);
            }
        };
    }, [iframeRef.current]);

    const handleClickOnOverlay = () => {
        document.cookie = "previousUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = `previousUrl=${encodeURIComponent("/subscription/ebook")}; path=/;`;
        if (!from) {
            alert("로그인이 필요합니다.");
            push("/login");
        } else {
            push("/subscription/ebook");
        }
    }; 
    

    return (
        <KirdContainer className="mt-20"  ebook={true}>
            { !from && <h3 className={style.ebookTitle}>{title}</h3>}
                {loginInfo == null && !from && (
                    <div
                        className={style.ebookWrap}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            opacity: overlayVisible ? 1 : 0,
                            pointerEvents: overlayVisible ? 'auto' : 'none',
                            overflow: "hidden"
                        }}
                        onClick={handleClickOnOverlay}
                    />
                )}
                { from && (
                    <div
                        className={style.ebookWrapMain}
                        style={{
                            position: 'absolute',
                            left: 0,
                            width: '100%',
                            opacity: overlayVisible ? 1 : 0,
                            pointerEvents: overlayVisible ? 'auto' : 'none',
                            overflow: "hidden",
                        }}
                        onClick={handleClickOnOverlay}
                    />
                )}
                <iframe
                    className={style.ebookWrap}
                    ref={iframeRef}
                    src={url}
                    width="100%"
                    scrolling="no"
                    frameBorder="0"
                    title='이 달의 전자책'
                    style={{overflow: "hidden"}}
                />
            {/* <div className={from && style.ebookTotalWrap}>
                <div className={style.ebookTitleHome}>{title}</div>
                <div>
                    {loginInfo == null && (
                        <div
                            className={from ? style.ebookWrapHome : style.ebookWrap}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                opacity: overlayVisible ? 1 : 0,
                                pointerEvents: overlayVisible ? 'auto' : 'none',
                                overflow: "hidden"
                            }}
                            onClick={handleClickOnOverlay}
                        />
                    )}
                    <iframe
                        className={from ? style.ebookWrapHome : style.ebookWrap}
                        ref={iframeRef}
                        src={url}
                        width="100%"
                        scrolling="no"
                        frameBorder="0"
                        title='전자책'
                        style={{overflow: "hidden"}}
                    />
                </div>
            </div> */}
        </KirdContainer>
    );
};
