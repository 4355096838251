import React from "react";
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import clsx from 'clsx';
import Thumbnail from "components/common/Thumbnail";
import {
  getMyRegistrationStatusCodeName,
  getSubjectTypeStudentCodeName
} from "shared/utils/CommonCode";
import { SubjectSlideItemType } from './VisualSubjectSlides';
import styles from './VisualSubjectCard.module.css';

const SubjectCard = ({item, position}:{item:SubjectSlideItemType, position?: string}) => {
  const {t} = useTranslation();
  return (
    <div className={styles.cptThumVsl}>
      <Link  href={item.href ? item.href : '/study' }>
        <a className={styles.thumLink}>
          <div className={styles.status}>
            <div className={`flag${item?.bannerRegistrationStatus}`}>
              <span className={styles.txt}>{getMyRegistrationStatusCodeName[item?.bannerRegistrationStatus]}</span>
            </div>
          </div>
          <div className={styles.thumImgGroup}>
            <div className={styles.imgBox}>
              <Thumbnail
                // id={item?.thumbnail?.id}
                alt={item?.name}
                className={clsx('inline w-11/12 group-hover:scale-105 transform transition duration-300', {["!w-[212px] !h-[174px]"]: position === "banner"})}
              />
            </div>
            <div className={styles.imgInfo}>
              <div className={styles.badgeBox}>
                {item?.isNew &&
                <div className={styles.badgeBlue}>NEW</div>
                }
                <div className={styles.badgeBlack}>
                  {t(getSubjectTypeStudentCodeName[item?.type]) === "조합교육" ? "온라인교육" : t(getSubjectTypeStudentCodeName[item?.type])}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.thumContGroup}>
            <div className={styles.thumTitle}><strong>{item?.sequenceName}</strong></div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default SubjectCard;
