import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as ReactDOMServer from "react-dom/server";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { gql, useLazyQuery } from "@apollo/client";
import clsx from "clsx";
import { StoredFile } from "components/types/StoredFile";
import { Registration } from "components/types/Registration";
import { RegistrationStatus } from "shared/utils/CommonCode";
import { isMobileApp, isMobileBrowser } from "shared/utils/CommonUtil";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Navigation, Autoplay, Pagination } from "swiper";
import { PaginationOptions } from "swiper/types/components/pagination";
import "swiper/swiper-bundle.min.css";
import VisualSubjectSlides from "./VisualSubjectSlides";
import styles from "./Visual.module.css";

const BANNER_CHANGE_TIME = 5000;

const BANNER_TITLE_HEIGHT = 32;

interface CustomSwiperCssWrapperProps {
  currentIndex: number;
  bannerLength: number;
}
const CustomSwiperCssWrapper = styled.div<CustomSwiperCssWrapperProps>`
  .swiper-pagination-bullets-dynamic {
    overflow: visible;
  }

  .swiper-pagination-bullets {
    top: 66px;
    right: 59px;
    left: auto !important;
    display: flex;
    flex-direction: column;
    width: 50% !important;
    height: 160px;
    transform: translate(0) !important;
    transition: transform 0.2s;
    overflow-y: hidden;

    @media screen and (min-width: 100px) and (max-width: 1023px) {
      top: 75px;
      right: 20px;
    }

    @media screen and (min-width: 100px) and (max-width: 640px) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    margin: 0 !important;
    width: 100%;
    transform: translateY(
        ${(props) => {
          // NOTE: loop를 사용하면 0번째 index가 마지막 슬라이드가 된다
          if (props.currentIndex === 0) return props.bannerLength * -BANNER_TITLE_HEIGHT + BANNER_TITLE_HEIGHT * 2;
          if (props.currentIndex === props.bannerLength + 1) return 0;
          return props.currentIndex * -BANNER_TITLE_HEIGHT + BANNER_TITLE_HEIGHT * 2;
        }}px
      )
      scale(1);
  }

  .swiper-pagination-bullet-active-main {
    opacity: 1;
    display: block;
  }

  .swiper-pagination-bullet-active-prev-prev {
    display: block;
  }

  .swiper-pagination-bullet-active-prev {
    display: block;
  }

  .swiper-pagination-bullet-active-next {
    display: block;
  }

  .swiper-pagination-bullet-active-next-next {
    display: block;
  }

  .swiper-pagination-bullet-active-main {
    .bannerText::after {
      content: "";
      width: 16px;
      height: 16px;
      background: url(/images/icon/ico-16-arrow-right-2.svg) 50% no-repeat;
      position: absolute;
      left: -24px;
      top: 8px;
    }
  }
`;

const MainBannerWrapper = styled.div`
  font-size: 18px !important;
  color: #ffffff !important;
  background-color: transparent;
  height: ${BANNER_TITLE_HEIGHT}px;
  opacity: 0.7;
  left: 0 !important;
`;

const MainBannerTextContainer = styled.div`
  text-align: right;
`;

const MainBannerText = styled.span`
  position: relative;
  display: inline-block;
`;

// 나의 수강 신청 검색
const SELECT_SEARCH_MY_REGISTRATION = gql`
  query SearchMyRegistration($request: SearchMyRegistrationInput) {
    myRegistrations: searchMyRegistration(request: $request) {
      content {
        id
        bannerRegistrationStatus
        sequence {
          id
          name
          subject {
            id
            name
            type
            thumbnail {
              id
              name: originFileName
              size
              contentType
            }
          }
        }
      }
    }
  }
`;

// 배너 검색
const SEARCH_BANNER = gql`
  query SearchBanner($request: SearchBannerInput) {
    banners: searchBanner(request: $request) {
      content {
        id
        url
        use
        title
        alt
        sorted
        attachment {
          id
          name: originFileName
          size
          contentType
        }
        mobAttachment {
          id
          name: originFileName
          size
          contentType
        }
      }
      number
      size
      totalPages
      totalElements
    }
  }
`;

interface BannerType {
  id: string;
  attachment: StoredFile;
  mobAttachment: StoredFile;
  url: string;
  use: string;
  title: string;
  alt?: string;
  status: "create" | "edit" | "remove";
}

SwiperCore.use([Navigation, Controller, Autoplay, Pagination]);

/**
 * 홈 - 배너(Banner) 컴포넌트
 */
export default function Visual({ userName }: { userName?: string }) {
  const { t } = useTranslation();
  const { isReady, query, push } = useRouter();

  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore | undefined>(undefined);

  // 비쥬얼 순번
  const [visualIndex, setVisualIndex] = useState(0);

  // 모바일 여부
  const [isMobile, setMobile] = useState<boolean>(false);

  // 나의 수강 신청 검색
  const [fnSearchMyRegistration, { data: myRegistrationsData }] = useLazyQuery(SELECT_SEARCH_MY_REGISTRATION, {
    fetchPolicy: "no-cache",
  });

  // 나의 수강 신청 목록
  const myRegistrations = myRegistrationsData?.myRegistrations?.content?.map((myRegistration: Registration) => ({
    href: `/study/${myRegistration.id}`,
    name: myRegistration.sequence?.subject?.name,
    type: myRegistration.sequence?.subject?.type,
    thumbnail: myRegistration.sequence?.subject?.thumbnail,
    sequenceName: myRegistration.sequence?.name,
    bannerRegistrationStatus: myRegistration?.bannerRegistrationStatus,
  }));

  const [bannerList, setBannerList] = useState<BannerType[]>([]);

  // 배너 검색
  const [fnSearchBanner, { loading: bannersLoading, data: bannersData, error: bannersError }] = useLazyQuery(SEARCH_BANNER, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      const yBannerList: BannerType[] | undefined = bannersData?.banners?.content?.filter((banner: BannerType) => banner.use == "Y");
      if (userName) {
        // insert userBanner banner to 0 index
        const userBanner: BannerType = {
          id: "userBannerId",
          attachment: {
            id: "userBannerAttachmentId",
            name: "userBannerAttachmentName",
            size: 0,
            contentType: "image/png",
          },
          mobAttachment: {
            id: "userBannerAttachmentId",
            name: "userBannerAttachmentName",
            size: 0,
            contentType: "image/png",
          },
          url: "",
          use: "Y",
          title: "수강/대기과정",
          alt: "userBanner",
          status: "create",
        };
        setBannerList(yBannerList && [userBanner, ...yBannerList]);
        controlledSwiper.slideTo(1);
        return;
      }
      setBannerList(yBannerList);
      controlledSwiper.slideTo(1);
      return;
    },
  });

  useEffect(() => {
    if (isReady) {
      setMobile(isMobileBrowser() || isMobileApp());
      let searchMyRegistrationRequest: any = {
        page: {
          number: 0,
          size: 20,
          sort: [
            {
              property: "createdDateTime",
              direction: "desc",
            },
          ],
        },
        statuses: [RegistrationStatus.APPROVED, RegistrationStatus.NOT_COMPLETED],
        studying: true,
      };

      // 나의 수강 신청 검색
      fnSearchMyRegistration({
        variables: { request: { ...searchMyRegistrationRequest } },
      });

      let searchBannerRequest: any = {
        page: {
          number: query.number || 0,
          size: 20,
          sort: [
            {
              property: "createdDateTime",
              direction: "asc",
            },
          ],
        },
      };

      // 배너 검색
      fnSearchBanner({ variables: { request: { ...searchBannerRequest } } });
    }
  }, [query]);

  // autoplay 상태를 저장하는 state
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const tempRef = useRef<HTMLDivElement>(null);

  const handlePaginationChange = (pageNumber: number) => {
    if (pageNumber <= 0) {
      pageNumber = totalPages;
    } else if (pageNumber > totalPages) {
      pageNumber = 1;
    }
    setCurrentPage(pageNumber);
    setVisualIndex(pageNumber - 1);
    controlledSwiper?.slideTo(pageNumber);
    resetProgressBar();
  };

  {/* NOTE kig pc의 경우에만 노출 */}
  const paginationOptions: PaginationOptions = {
    clickable: true,
    renderBullet: function (index, className) {
      return ReactDOMServer.renderToStaticMarkup(
        <MainBannerWrapper className={`${className}`}>
          <MainBannerTextContainer>
            {/* NOTE kig pc의 경우에만 노출 */}
            {!isMobile && <MainBannerText className="bannerText">{bannerList?.[index]?.title ?? index + 1}</MainBannerText>}
          </MainBannerTextContainer>
        </MainBannerWrapper>
      );
    },
    dynamicBullets: isMobile ? false : true,
  };

  useEffect(() => {
    if (bannerList) {
      setTotalPages(bannerList.length);
    }
  }, [bannerList]);

  useEffect(() => {
    if (controlledSwiper) {
      if (controlledSwiper.activeIndex > totalPages) {
        setCurrentPage(1);
      } else {
        if ( controlledSwiper.activeIndex == 0 ){
          setCurrentPage(totalPages)
        } else {
          setCurrentPage(controlledSwiper.activeIndex);
        }
      }
    }
  }, [controlledSwiper?.activeIndex]);

  const handleControlButtonClick = () => {
    if (isPlaying) {
      controlledSwiper?.autoplay.stop();
      progressBarRef.current!.style.transition = 'none'; // 일시정지 시 진행 바 멈춤
    } else {
      controlledSwiper?.autoplay.start();
      resetProgressBar(); // 재생 시 진행 바 리셋
    }
    setIsPlaying(!isPlaying);
  };

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const progressBarRef = useRef<HTMLDivElement>(null);

  // Progress Bar 애니메이션 트리거
  const resetProgressBar = () => {
    if (progressBarRef.current) {
      // Step 1: 애니메이션 초기화
      progressBarRef.current.style.transition = 'none';
      progressBarRef.current.style.width = '0%';

      // Step 2: Reflow 강제 (애니메이션 적용 보장)
      progressBarRef.current.getBoundingClientRect();

      // Step 3: 애니메이션 재시작
      progressBarRef.current.style.transition = 'width 5s linear';
      progressBarRef.current.style.width = '100%';
    }
  };

  useEffect(() => {
    resetProgressBar(); // 컴포넌트 마운트 시 최초 Progress Bar 트리거
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const initialWidth = window.innerWidth;
      setWidth(initialWidth);
      setHeight(initialWidth * 0.625);

      const handleResize = () => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
        setHeight(newWidth * 0.625);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return (
    <div className={styles.lytMainVisual}>
      <div className={styles.visualArea}>
        <CustomSwiperCssWrapper currentIndex={visualIndex} bannerLength={bannerList?.length ?? 0}>
          {bannersData?.banners && (
            <div className={styles.controlWrap}>
              <div className={styles.autoProgress}>
                <div ref={progressBarRef} className={styles.progressBar} />
              </div>
              <div className={styles.controlButton}>
                <span className={styles.paginationNum}>{`${currentPage} / ${totalPages}`}</span>
                <button className={styles.paginationButton} onClick={() => handlePaginationChange(currentPage - 1)}>
                  {"<"}
                </button>
                <button onClick={handleControlButtonClick}>
                  {isPlaying ? (
                    <img src="/images/icon/iconPauseWhite.svg" alt="메인화면 배너 자동 변경 일시정지 이미지" />
                  ) : (
                    <img src="/images/icon/iconPlayWhite.svg" alt="메인화면 배너 자동 변경 재생 이미지" />
                  )}
                </button>
                <button className={styles.paginationButton} onClick={() => handlePaginationChange(currentPage + 1)}>
                  {">"}
                </button>
              </div>
            </div>
          )}

          {/* 비주얼 영역 START */}
          <Swiper
            speed={500}
            slidesPerView={1}
            spaceBetween={0}
            followFinger={false}
            // allowTouchMove={false}
            onSwiper={setControlledSwiper}
            autoplay={{
              delay: BANNER_CHANGE_TIME,
              disableOnInteraction: false, // 네비 클릭 후 멈추지 않도록 설정
            }}
            // NOTE: loop를 사용하면 0번째 index가 마지막 슬라이드가 된다
            loop
            pagination={isMobile && paginationOptions}
            onSlideChange={(swiper) => {
              setVisualIndex(swiper.activeIndex);
              resetProgressBar // 슬라이드 변경 시 Progress Bar 리셋
            }}
            onAutoplayStart={resetProgressBar} // Autoplay 시작 시 Progress Bar 초기화
            onSlideChangeTransitionEnd={resetProgressBar}
          >
            {/* 배너 영역 START */}
            {bannerList?.map((banner, index) => {
              if (userName && index === 0) {
                // 로그인 상태이고 첫번째 배너일 경우 수강/대기과정 영역을 먼저 보여준다.
                return (
                  <SwiperSlide key={banner.id} className={isMobile ? "" : clsx(styles.visualList, "!w-full userLogin")} style={isMobile ? { width: `${width}px`, height: `${height}px` } : {}}>
                    <VisualSubjectSlides
                      id="myRegistrationsSlide"
                      list={myRegistrations}
                      title={`${userName}${t("님 수강/대기 과정입니다")}`}
                      position="banner"
                    />
                  </SwiperSlide>
                );
              }
              return (
                <SwiperSlide key={banner.id} className={isMobile ? "" : clsx(styles.visualList, "!w-full userLogin")} style={isMobile ? { width: `${width}px`, height: `${height}px` } : {}}>
                  <div className={styles.bannerContentsGroup}>
                    <div className={styles.bannerBg}>
                      <div className={styles.bannerImage} ref={tempRef}>
                        <a href={`${banner.url}`}>
                          <Image
                            layout="fill"
                            loader={() => (isMobile && banner?.mobAttachment ? `/api/file/${banner?.mobAttachment?.id}?accessToken=ok` : `/api/file/${banner?.attachment?.id}?accessToken=ok`)}
                            src={isMobile && banner?.mobAttachment ? `/api/file/${banner?.mobAttachment?.id}?accessToken=ok` : `/api/file/${banner?.attachment?.id}?accessToken=ok`}
                            width={tempRef?.current?.offsetWidth || 0}
                            height={tempRef?.current?.offsetHeight || 0}
                            alt={banner?.alt || banner?.attachment?.name}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
            {/* 배너 영역 END */}
          </Swiper>
          {/* 비주얼 영역 END */}
        </CustomSwiperCssWrapper>
      </div>
    </div>
  );
}
