import KirdContainer from "components/layouts/KirdContainer";
import React from "react";
import styles from "./VisualSubjectSlides.module.css";
import SwiperCore, { Navigation } from "swiper";
import VisualSubjectCard from "./VisualSubjectCard";
import { StoredFile } from "components/types/StoredFile";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {string} from "yup";

// CSS
const useStyles = makeStyles((theme) => ({
  swiperWrapper: {
    "& .swiper-wrapper": {
      justifyContent: "left",
    },
    "& .swiper-wrapper .swiper-slide": {
      marginLeft: 75,
      "@media (max-width: 1024px)": {
        marginLeft: 0,
      },
    },
  },
}));

SwiperCore.use([Navigation]);

export interface SubjectSlideItemType {
  status: string;
  href: string;
  name: string;
  type: string;
  thumbnail: StoredFile;
  isNew?: boolean;
  position?: string;
  sequenceName?: string;
  bannerRegistrationStatus?: string;
}

interface Props {
  id?: string;
  title: string;
  list: Array<SubjectSlideItemType>;
  position?: string;
}

/**
 * Visual (Hero) 영역 과정 slider
 * for 수강/대기과정, 키워드추천, 인기콘텐츠
 * @param props
 * @constructor
 */
export default function VisualSubjectSlides(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id, title, list, position } = props;

  return (
    <div className={styles.loginContentsGroup} id="mainMyRegistration">
      <KirdContainer>
        <div className={styles.visualTit}>
          <h2>
            <span dangerouslySetInnerHTML={{ __html: title }}></span>&nbsp;<span className={styles.num}>{list?.length}</span>
          </h2>
        </div>
        {list && list.length > 0 && (
          <React.Fragment>
            <div className={styles.visualCont}>
              <Swiper
                speed={500}
                slidesPerView="auto"
                spaceBetween={16}
                centeredSlides={false}
                navigation={{ prevEl: `#mainMyRegistration .btnPrev`, nextEl: `#mainMyRegistration .btnNext` }}
                breakpoints={{
                  1024: {
                    spaceBetween: -27,
                    centeredSlides: false,
                  },
                  280: {
                    spaceBetween: 16,
                    centeredSlides: false,
                  },
                }}
                className={classes.swiperWrapper}
              >
                {list.map((item: SubjectSlideItemType) => (
                  <SwiperSlide className={styles.subjectCard} key={item.href}>
                    <VisualSubjectCard item={item} position={position || ""} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className={styles.pagenation}>
              <button type="button" className={clsx(styles.btnPrev, "btnPrev")}>
                {t("이전")}
              </button>
              <button type="button" className={clsx(styles.btnNext, "btnNext")}>
                {t("다음")}
              </button>
            </div>
          </React.Fragment>
        )}
        {(!list || list.length === 0) && (
          <div className={styles.visualCont}>
            <div className="inline-flex justify-center w-full">
              <Link href="/explore">
                <a>
                  <img src="/images/temp/main-myRegistrations-empty.png" alt="신청하신 과정이 없습니다" />
                </a>
              </Link>
            </div>
          </div>
        )}
      </KirdContainer>
    </div>
  );
}
