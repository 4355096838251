import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Thumbnail from "../common/Thumbnail";
import { Channel } from "components/types/Channel";
import styles from './ChannelCard.module.css';

interface Props {
  channel: Channel;
}

export default function ChannelCard(props: Props) {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { channel } = props;

  return (
    <div className={styles.cptRoundthum} tabIndex={0} onClick={() => push(`/subscription/channel/${channel.id}`)}>
      <a className={styles.roundthumLink}>
        <div className={styles.roundthumImgGroup}>
          <div className={styles.imgBox}>
            <Thumbnail
              id={channel.thumbnail?.id}
              alt={channel.name}
            />
          </div>
        </div>
        <div className={styles.roundthumContGroup}>
          <div className={styles.roundthumTitle}>
            <strong className={styles.tit} title={channel.name}>{channel.name}</strong>
          </div>
          <div className={styles.roundthumHost}>
            <p className={styles.name}>{channel.influencer?.name}</p>
          </div>
          <div className={styles.roundthumInfo}>
            <ul>
              <li className={styles.infoItem}>
                <span className={styles.hide}>{t('조회수')}:</span>
                <span className={styles.view}>{(channel.views + channel.nonLoginViews)?.toLocaleString() || 0}</span>
              </li>
              <li className={styles.infoItem}>
                <span className={styles.hide}>{t('리스트')}:</span>
                <span className={styles.list}>{channel.numberOfContent?.toLocaleString() || 0}</span>
              </li>
            </ul>
          </div>
        </div>
      </a>
      <button type="button" className={styles.btnSubscribe}>
        <span className={styles.txt}>구독하기</span>
      </button>
      {/*<button type="button" className={styles.btnMore} onClick={() => push(`/subscription/channel/${channel.id}`)} title={`${channel.name} 더보기`} />*/}
    </div>
  );
};
